h1 {
  font-size: 3em;
}
p {
  padding: 1em;
}
h2 {
  font-weight: bold;
  font-size: 2em;
}
h3 {
  font-weight: bold;
}
ul {
  list-style-type: disc; /* Ensure bullets are displayed */
  margin-left: 20px; /* Adjust margin if necessary */
}

li {
  margin-bottom: 10px; /* Add some spacing between list items */
}
