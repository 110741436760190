.arrow-up,
.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.arrow-up {
  border-bottom: 10px solid #000; /* Arrow color */
  margin-bottom: 5px; /* Space between arrow and text */
  animation: bounce 1s infinite;
}

.arrow-down {
  border-top: 10px solid #000; /* Arrow color */
  margin-top: 5px; /* Space between arrow and text */
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2.5px);
  }
}
