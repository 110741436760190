/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "SF Pro Display Regular";
  src: url("./assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "SF Pro Display", sans-serif;
}
@media (max-width: 600px) {
  body {
    font-size: 16px; /* Adjust font size */
  }
  .container {
    padding: 10px; /* Adjust padding */
  }
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
